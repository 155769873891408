import authService from '../../../../services/auth'
import { Auth } from 'aws-amplify'
export default {
    data() {
        return {
            password: {              
                old_password: null,
                new_password: null,
                confirm_password: null
            },
        }
    },
    methods: {
        forceRerender () {
            this.$refs.modal.show()
        },
        async changePassword () {
            var isValid = await this.$refs.observer.validate()
            if (isValid) {
                // authService.changePassword(this.password).then(resp => {
                //     if (!resp.error) {
                //         this.$refs.modal.hide()
                //         this._showToast('Password changed!')                    
                //     }
                // })
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        return Auth.changePassword(user, this.password.old_password, this.password.new_password).then(data =>{
                            debugger
                            this.$refs.modal.hide()
                            this._showToast('Password changed!')                    
                            })
                            .catch(err =>{
                                debugger
                              //this._showToast(err.message, { variant: 'warning' })
                              //console.log(err)
                            });
                    })
                    // .then(data => console.log(data))
                    // .catch(err => console.log(err));
                //await  Auth.changePassword(user, this.password.old_password, this.password.new_password)
                  
            }
        },
    }
}